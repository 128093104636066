import videoBg from "./assets/video/sectionbg.mp4";
import logo from "./assets/img/logo.png";
import logo2 from "./assets/img/logo-2.png";
import dt from "./assets/img/dt.svg";
import us from "./assets/img/us.svg";
import es from "./assets/img/es.svg";
import ds from "./assets/img/ds.svg";
import j1 from "./assets/img/j-1.png";
import j2 from "./assets/img/j-2.png";
import j3 from "./assets/img/j-3.png";
import j4 from "./assets/img/j-4.png";
import j5 from "./assets/img/j-5.png";

import AOS from "aos";
import "aos/dist/aos.css";

import "./App.scss";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 800,
    });
    AOS.refresh();
  }, []);

  return (
    <div className="App">
      <header className="py-5">
        <div className="flex flex-col lg:flex-row items-center gap-3 justify-between container mx-auto px-3 lg:px-20">
          <div className="w-52">
            <img src={logo} alt="" />
          </div>
          <ul className="flex items-center gap-3 lg:gap-10">
            <li>
              <a>Contact</a>
            </li>
            <li>
              <a>Features</a>
            </li>
            <li>
              <a
                href="https://mevwatchbot.gitbook.io/docs/"
                target="_blank"
                rel="noopener noreferrer">
                Documentations
              </a>
            </li>
            <li>
              <a>Community</a>
            </li>
          </ul>
        </div>
      </header>

      <section className="min-h-screen relative">
        <video
          className="object-cover w-screen"
          playsInline
          autoPlay
          muted
          loop>
          <source src={videoBg} type="video/mp4" />
        </video>
        <div className="absolute text-center w-full max-w-4xl mx-auto px-5 lg:px-20 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <h1 className="text-4xl lg:text-7xl">
            Powerful for Snipers
            <br />
            Fast for everyone
          </h1>
          <p className="text-lg lg:text-xl my-9">
            Bring blockchain to the people. MevWatchBot supports experiences for
            power users, new consumers, and everyone in between.
          </p>
          <div className="flex justify-center gap-5">
            <a
              href="#"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-white inline-flex justify-center items-center text-black py-3 lg:py-4 px-5 lg:px-8 rounded-full hover:scale-105 hover:opacity-75 transition-all">
              Start Using BOT
            </a>
            <a
              href="https://mevwatchbot.gitbook.io/docs/"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-black text-white border-white border-[1px] py-4 px-8 rounded-full hover:scale-105 hover:opacity-75 transition-all">
              Read docs
            </a>
          </div>
          <div className="mt-20 lg:mt-36">
            <span className="uppercase">
              supported by decentralized exchanges around the world
            </span>
            <div className="flex gap-2 lg:gap-10 mt-8">
              <div>
                <img src={dt} alt="" />
              </div>
              <div>
                <img src={us} alt="" />
              </div>
              <div>
                <img src={es} alt="" />
              </div>
              <div>
                <img src={ds} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="py-10 lg:py-20 bg-[url('./assets/img/bg-about.png')] bg-top bg-cover bg-no-repeat"
        data-aos="fade-up">
        <div className="container mx-auto px-5 lg:px-20">
          <div className="flex flex-col lg:flex-row items-center gap-8 lg:gap-28 xl:gap-32">
            <h3 className="max-w-full text-center lg:text-left lg:max-w-md text-3xl lg:text-6xl font-bold lg:mt-28">
              Professional toolkit on Ethereum.
            </h3>
            <ul className="flex flex-col gap-11">
              <li className="text-center lg:text-left">
                <h3 className="text-gradient-1 text-7xl lg:text-8xl leading-relaxed">
                  1.5M+
                </h3>
                <span className="text-gray">USERS ACTIVE</span>
              </li>
              <li className="text-center lg:text-left">
                <h3 className="text-gradient-2 text-7xl lg:text-8xl leading-relaxed">
                  1.9M
                </h3>
                <span className="text-gray">USERS ACTIVE</span>
              </li>
              <li>
                <h3 className="text-gradient-3 text-7xl lg:text-8xl leading-relaxed">
                  0.001S
                </h3>
                <span className="text-gray">USERS ACTIVE</span>
              </li>
            </ul>
          </div>
          <div className="mt-32 flex flex-col lg:flex-row items-center gap-10 lg:gap-20">
            <div
              className="w-80 flex-shrink-0 text-center lg:text-left "
              data-aos="fade-right">
              <h3 className="text-4xl">
                Made for <br className="hidden lg:block" />
                mass adoption.
              </h3>
              <p className="flex gap-2 justify-center lg:justify-start items-center mt-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="5"
                  height="5"
                  viewBox="0 0 5 5"
                  fill="none">
                  <path
                    d="M0.588867 2.67236C0.588867 1.48878 1.54835 0.529297 2.73193 0.529297C3.91552 0.529297 4.875 1.48878 4.875 2.67236V2.85693C4.875 4.04052 3.91552 5 2.73193 5C1.54835 5 0.588867 4.04052 0.588867 2.85693V2.67236Z"
                    fill="#1FCFF1"
                  />
                </svg>
                <span className="uppercase">Live data</span>
              </p>
            </div>
            <div
              className="grid grid-cols-1 lg:grid-cols-2 gap-10"
              data-aos="fade-left">
              <div className="grid grid-cols-1 gap-10 mt-12 lg:mt-20">
                <div className="flex flex-col">
                  <h3 className="px-4 border-l-2 text-2xl border-l-[#F11F9D]">
                    Potential Snipers
                  </h3>
                  <p className="text-gray mt-2">
                    Highlight and provide detailed information about accounts
                    with sniper-like behavior, who engage in sudden trades to
                    exploit opportunities.
                  </p>
                  <div className="flex gap-2 mt-4 my-3">
                    <svg
                      className="mt-2"
                      xmlns="http://www.w3.org/2000/svg"
                      width="5"
                      height="5"
                      viewBox="0 0 5 5"
                      fill="none">
                      <path
                        d="M0 2.14307C0 0.959483 0.959484 0 2.14307 0C3.32665 0 4.28613 0.959484 4.28613 2.14307V2.32764C4.28613 3.51122 3.32665 4.4707 2.14307 4.4707C0.959484 4.4707 0 3.51122 0 2.32764V2.14307Z"
                        fill="#1FCFF1"
                      />
                    </svg>
                    <span className="text-2xl">0.001s</span>
                  </div>
                  <span className="text-gray uppercase">
                    Real-time scanning speed
                  </span>
                </div>
                <div className="flex flex-col">
                  <h3 className="px-4 border-l-2 text-2xl border-l-[#9945FF]">
                    Contract Analytics
                  </h3>
                  <p className="text-gray mt-2">
                    Provide in-depth analytics for smart contracts, display the
                    contract’s detailed information and their interactions on
                    the network.
                  </p>
                  <div className="flex gap-2 mt-4 my-3">
                    <svg
                      className="mt-2"
                      xmlns="http://www.w3.org/2000/svg"
                      width="5"
                      height="5"
                      viewBox="0 0 5 5"
                      fill="none">
                      <path
                        d="M0 2.14307C0 0.959483 0.959484 0 2.14307 0C3.32665 0 4.28613 0.959484 4.28613 2.14307V2.32764C4.28613 3.51122 3.32665 4.4707 2.14307 4.4707C0.959484 4.4707 0 3.51122 0 2.32764V2.14307Z"
                        fill="#1FCFF1"
                      />
                    </svg>
                    <span className="text-2xl">163,077,581,394</span>
                  </div>
                  <span className="text-gray">TOTAL REQUIREMENTS</span>
                </div>
              </div>
              <div className="grid grid-cols-1 gap-10">
                <div className="flex flex-col">
                  <h3 className="px-4 border-l-2 text-2xl border-l-[#FFD512]">
                    Alpha Wallets
                  </h3>
                  <p className="text-gray mt-2">
                    Track wallets associated with the project, providing
                    detailed insights into the activities and assets within
                    these wallets, ensuring transparency.
                  </p>
                  <div className="flex gap-2 mt-4 my-3">
                    <svg
                      className="mt-2"
                      xmlns="http://www.w3.org/2000/svg"
                      width="5"
                      height="5"
                      viewBox="0 0 5 5"
                      fill="none">
                      <path
                        d="M0 2.14307C0 0.959483 0.959484 0 2.14307 0C3.32665 0 4.28613 0.959484 4.28613 2.14307V2.32764C4.28613 3.51122 3.32665 4.4707 2.14307 4.4707C0.959484 4.4707 0 3.51122 0 2.32764V2.14307Z"
                        fill="#1FCFF1"
                      />
                    </svg>
                    <span className="text-2xl">0.001s</span>
                  </div>
                  <span className="text-gray uppercase">
                    Real-time scanning speed
                  </span>
                </div>
                <div className="flex flex-col">
                  <h3 className="px-4 border-l-2 text-2xl border-l-[#FB1919]">
                    Simulation Report
                  </h3>
                  <p className="text-gray mt-2">
                    Monitor and analyze the token's performance through various
                    indicators, allowing them to gain insights into its behavior
                    and make informed decisions.
                  </p>
                  <div className="flex gap-2 mt-4 my-3">
                    <span className="text-2xl">0%</span>
                  </div>
                  <span className="text-gray uppercase">Net carbon impact</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-10 lg:py-20" data-aos="fade-up">
        <div className="container mx-auto px-5 lg:px-20">
          <div className="flex flex-col lg:flex-row justify-center items-center lg:justify-between gap-5">
            <span className="text-2xl lg:text-4xl">
              More features for growth.
            </span>
            <ul className="flex gap-3 text-center lg:gap-8">
              <li className="">
                <a className="bg-[#111] inline-flex p-3 rounded-xl border-[#F09] border">
                  Fresh Wallets
                </a>
              </li>
              <li className="">
                <a className="bg-[#111] inline-flex p-3 rounded-xl">
                  Honeypot Warnings
                </a>
              </li>
              <li className="">
                <a className="bg-[#111] inline-flex p-3 rounded-xl ">
                  Socials Scan
                </a>
              </li>
            </ul>
          </div>

          <div className="mt-24 bg-[#1A1A1A] rounded-[30px] py-7 px-8 lg:px-28 flex flex-col lg:flex-row gap-10 lg:gap-28 justify-between">
            <div className="max-w-sm">
              <img src={logo2} alt="" />
            </div>
            <div className="text-center flex flex-col justify-center items-center">
              <h3 className="text-xl lg:text-3xl font-bold">
                WANT TO WORK WITH US?
              </h3>
              <span className="text-base lg:text-lg my-6">
                Send email to become a part of the professional team
                <br />
                <span className="text-gray text-sm">
                  contact@mevwatchbot.com
                </span>
              </span>
              <a className="bg-white text-black py-4 px-8 rounded-full inline-flex w-fit hover:scale-105 hover:opacity-75 transition-all">
                SEND EMAILS
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="py-10 lg:py-20">
        <div class="slider">
          <div class="slide-track">
            <div class="flex flex-col gap-[20px]">
              <div className="w-[300px] h-[200px] flex flex-col justify-center items-center gap-5 bg-[#19161C] px-5 py-8 text-center rounded-xl">
                <h3 className="text-4xl text-[#F90]">2,000</h3>
                <span className="uppercase text-[#bababa]">
                  Blockchain DEVELOPERS House participants
                </span>
              </div>
              <img
                src={j1}
                className="w-[300px] h-[200px] object-cover rounded-xl"
                alt=""
              />
            </div>
            <div class="flex flex-col">
              <img
                src={j2}
                className="w-[600px] h-[420px] object-cover rounded-xl"
                alt=""
              />
            </div>
            <div class="flex flex-col gap-[20px]">
              <img
                src={j3}
                className="w-[300px] h-[200px] object-cover rounded-xl"
                alt=""
              />
              <div className="w-[300px] h-[200px] flex flex-col justify-center items-center gap-5 bg-[#19161C] px-5 py-8 text-center rounded-xl">
                <h3 className="text-4xl text-[#FB1985]">48,000</h3>
                <span className="uppercase text-[#bababa]">
                  USERS ARE USING MEVWATCHBOT IN TRADING
                </span>
              </div>
            </div>
            <div class="flex flex-col">
              <img
                src={j4}
                className="w-[300px] h-[420px] object-cover rounded-xl"
                alt=""
              />
            </div>
            <div class="flex flex-col gap-[20px]">
              <div className="w-[300px] h-[200px] flex flex-col justify-center items-center gap-5 bg-[#19161C] px-5 py-8 text-center rounded-xl">
                <h3 className="text-4xl text-[#FFEB3B]">3,800</h3>
                <span className="uppercase text-[#bababa]">
                  members attending the MevWatch Bot seminar
                </span>
              </div>
              <img
                src={j5}
                className="w-[300px] h-[200px] object-cover rounded-xl"
                alt=""
              />
            </div>
            <div class="flex flex-col gap-[20px]">
              <div className="w-[300px] h-[200px] flex flex-col justify-center items-center gap-5 bg-[#19161C] px-5 py-8 text-center rounded-xl">
                <h3 className="text-4xl text-[#F90]">2,000</h3>
                <span className="uppercase text-[#bababa]">
                  Blockchain DEVELOPERS House participants
                </span>
              </div>
              <img
                src={j1}
                className="w-[300px] h-[200px] object-cover rounded-xl"
                alt=""
              />
            </div>
            <div class="flex flex-col">
              <img
                src={j2}
                className="w-[600px] h-[420px] object-cover rounded-xl"
                alt=""
              />
            </div>
            <div class="flex flex-col gap-[20px]">
              <img
                src={j3}
                className="w-[300px] h-[200px] object-cover rounded-xl"
                alt=""
              />
              <div className="w-[300px] h-[200px] flex flex-col justify-center items-center gap-5 bg-[#19161C] px-5 py-8 text-center rounded-xl">
                <h3 className="text-4xl text-[#FB1985]">48,000</h3>
                <span className="uppercase text-[#bababa]">
                  USERS ARE USING MEVWATCHBOT IN TRADING
                </span>
              </div>
            </div>
            <div class="flex flex-col">
              <img
                src={j4}
                className="w-[300px] h-[420px] object-cover rounded-xl"
                alt=""
              />
            </div>
            <div class="flex flex-col gap-[20px]">
              <div className="w-[300px] h-[200px] flex flex-col justify-center items-center gap-5 bg-[#19161C] px-5 py-8 text-center rounded-xl">
                <h3 className="text-4xl text-[#FFEB3B]">3,800</h3>
                <span className="uppercase text-[#bababa]">
                  members attending the MevWatch Bot seminar
                </span>
              </div>
              <img
                src={j5}
                className="w-[300px] h-[200px] object-cover rounded-xl"
                alt=""
              />
            </div>
          </div>
        </div>

        <div className="max-w-4xl w-full text-center mx-auto mt-32 px-5 lg:px-20">
          <h3 className="text-2xl lg:text-4xl">
            It's time to join the thousands of traders using MevWatchBot in
            trading
          </h3>
          <div className="flex justify-center gap-5 mt-10">
            <a
              href="https://t.me/MevWatchBot"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-white text-black py-4 px-12 rounded-full hover:scale-105 hover:opacity-75 transition-all">
              Telegram
            </a>
            <a
              href="https://twitter.com/MevWatchBotErc"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-black text-white border-white border-[1px] py-4 px-12 rounded-full hover:scale-105 hover:opacity-75 transition-all">
              Twitter
            </a>
          </div>
        </div>
      </section>

      <section className="py-10 lg:py-14">
        <div className="container mx-auto px-5 lg:px-20 flex flex-col-reverse lg:flex-row justify-center items-center lg:justify-between gap-10">
          <div className="flex flex-col items-center lg:items-start">
            <div className="w-44 mb-4">
              <img src={logo} alt="" />
            </div>
            <span>Developed by MevWatchBot Team</span>
            <div className="flex gap-5 my-7">
              <a
                href="https://twitter.com/MevWatchBotErc"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-[#848895] px-4 py-2 hover:scale-105 hover:opacity-75 transition-all">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  viewBox="0 0 16 16"
                  fill="none">
                  <path
                    d="M14.9118 4.25176C14.3913 4.48277 13.839 4.63414 13.2735 4.70077C13.8694 4.34454 14.3152 3.78354 14.5277 3.12259C13.9678 3.45503 13.3552 3.68927 12.7164 3.81516C12.4495 3.5304 12.1271 3.3035 11.7689 3.14854C11.4108 2.99357 11.0246 2.91385 10.6344 2.91431C9.05933 2.91431 7.78227 4.19136 7.78227 5.76672C7.78227 5.98996 7.80739 6.20755 7.85621 6.41668C5.48555 6.29758 3.38356 5.1622 1.97669 3.43642C1.72294 3.87175 1.58968 4.36679 1.59061 4.87067C1.59061 5.86014 2.09409 6.73333 2.85948 7.24472C2.40652 7.23035 1.96354 7.10795 1.56747 6.88771L1.56718 6.92383C1.56718 8.30559 2.55044 9.45819 3.85544 9.72065C3.43524 9.8344 2.99466 9.851 2.5671 9.76919C2.93032 10.9023 3.98357 11.7272 5.23155 11.7501C4.22125 12.5432 2.97351 12.9733 1.6891 12.9713C1.45909 12.9713 1.2319 12.9577 1.00867 12.9312C2.31283 13.7694 3.83081 14.2144 5.38113 14.2128C10.6279 14.2128 13.4967 9.86656 13.4967 6.09721C13.4967 5.97359 13.4942 5.85026 13.4888 5.72778C14.0473 5.32455 14.5292 4.82467 14.9118 4.25176Z"
                    fill="black"
                  />
                </svg>
              </a>
              <a
                href="https://t.me/MevWatchBot"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-[#848895] px-4 py-2 hover:scale-105 hover:opacity-75 transition-all">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  viewBox="0 0 16 16"
                  fill="none">
                  <path
                    d="M15.1465 3.06965L12.9773 13.3C12.8136 14.022 12.3868 14.2016 11.7803 13.8616L8.47501 11.4259L6.8802 12.9598C6.70373 13.1363 6.55608 13.284 6.21595 13.284L6.45339 9.91765L12.5794 4.38211C12.8457 4.14468 12.5216 4.01308 12.1654 4.25055L4.5922 9.01912L1.33186 7.99868C0.62267 7.77724 0.609857 7.28946 1.47945 6.94933L14.232 2.03637C14.8224 1.81496 15.3391 2.1679 15.1465 3.06965Z"
                    fill="black"
                  />
                </svg>
              </a>
            </div>
            <span className="text-[#848895]">
              @2023 MevWatchBot. All rights reserved.
            </span>
          </div>
          <div className="grid-cols-2 grid gap-20">
            <ul className="flex flex-col gap-2 text-[#848895]">
              <h4 className="text-white capitalize">Features</h4>
              <li>Potential Snipers</li>
              <li>Alpha Wallets</li>
              <li>Simulation Report</li>
              <li>Contract analytics</li>
              <li>Multiwallets</li>
            </ul>
            <ul className="flex flex-col gap-2 text-[#848895]">
              <h4 className="text-white">Get Connected</h4>
              <li>Email</li>
              <li>Blog</li>
              <li>Newsletter</li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
}

export default App;
